
import { onUnmounted, onMounted, defineComponent, inject, ref } from "vue";
import { IServiceBean } from "@/apis/interface/IServiceBean";
import {
  ServiceGroupMenuModel,
  ServiceGroupModel,
} from "@/apis/interface/IServiceGroup";
import { ServiceBeanModel } from "@/apis/interface/IServiceBean";
import { RespBody } from "@/apis/interface/Resp";
import bus from "@/utils/bus";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "List",
  components: {},
  setup() {
    const apiServiceBean = inject("apiServiceBean") as IServiceBean;

    let dataList = ref<ServiceGroupMenuModel[]>([]);
    let loading = ref(false);

    onUnmounted(() => {
      bus.off("on-table-row-edit-ok");
    });

    let loadData = async () => {
      loading.value = true;
      const resp = await apiServiceBean.menus();
      const list = resp as RespBody<ServiceGroupMenuModel[]>;
      dataList.value = list.object;
      // console.log("==>", dataList);
      loading.value = false;
    };

    let statusFormat = (status: number) => {
      // console.log(status);
      return "正常";
    };

    onMounted(() => {
      loadData();
      bus.on("on-table-row-edit-ok", () => {
        loadData();
      });
    });

    let editObject = (dataRow: ServiceGroupMenuModel) => {
      console.log("编辑");
      bus.emit("on-table-row-edit", {
        dataList: dataList.value,
        dataRow: dataRow,
      });
    };

    let clickCreateBeanGroup = () => {
      bus.emit("on-table-row-edit", {
        dataList: [],
        dataRow: new ServiceGroupModel({}),
      });
    };

    let clickCreateBean = (groupId: number) => {
      bus.emit("on-table-row-edit", {
        dataList: [],
        dataRow: new ServiceBeanModel({ groupId }),
      });
    };

    let deleteRow = async (id: number) => {
      let result = (await apiServiceBean.remove([id])) as RespBody<number>;
      if (result.object > 0) {
        ElMessage({
          type: "success",
          message: "刪除成功！",
        });
        await loadData();
      } else
        ElMessage({
          type: "error",
          message: "刪除失敗",
        });
    };

    return {
      loading,
      dataList,
      clickCreateBeanGroup,
      clickCreateBean,
      statusFormat,
      editObject,
      deleteRow,
    };
  },
});
